
@font-face {
    font-family: Montserrat;
    src: url(assets/fonts/Montserrat-Light.ttf);
}

body{
    background-color: #171836;
    width: 100% !important;
    overflow-x: hidden !important;
    font-family: Montserrat;
}

.row{
    margin: 0;
}

::-webkit-scrollbar{
    width: 8px !important;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #2091F9;
}